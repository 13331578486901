body,
#root {
    height: 100vh;
    width: 100%;
}

.header {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(221, 221, 221);
}

.container {
    display: flex;
    flex-direction: column;
    // max-width: 1400px;
    padding: 1rem 2rem;
}

.header-action {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.main-body{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .screenshot-tile{
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 10px;
        padding: 1rem;
        border: 1px solid #e0e0e0;
        box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.13);
        -webkit-box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.13);
        -moz-box-shadow: 1px 1px 13px 1px rgba(0,0,0,0.13);
        .description{
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
        }
    }
}

h5{
    margin: 0 !important;
    padding: 0 !important;
}
.no-data{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


@media (max-width: 457px) {

    .header,
    .header-action {
        justify-content: center;
    }
}

.authorization-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // max-width: 300px;
    gap: 2rem;
}